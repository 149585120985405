import React from "react";
// import EnlistProperty from "../../assets/images/enlist-property.png";
import { getS3BaseUrl } from "../../helpers/string_helper";

export const EnlistRegistration = ({ data }) => {
    return (
        <div className="img_section">
            {data?.banner_image_url &&
                (<img src={`${getS3BaseUrl()}${data?.banner_image_url}`} alt="" />)}
            <h4 dangerouslySetInnerHTML={{ __html: data?.header }}></h4>
            <p dangerouslySetInnerHTML={{ __html: data?.content }}></p>
        </div>
    )
}