import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { getImageType } from "../Common/amenities_icon";

function AmenitiesSlider({ files }) {
    const [documents, setDocuments] = useState()
    const sliderRef = useRef(null);
    const videoRef = useRef(null)

    useEffect(() => {
        if (files?.length > 0) {
            setDocuments(files)
        }
    }, [files])

    const goToPrevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    //  const handleLoadedMetadata = async (item) => {
    //     const video = videoRef.current;
    //     if (video) {
    //         const playPromise = video.play();
    //         if (playPromise !== null) {
    //             playPromise.catch(() => { video.play(); })
    //         }
    //     }
    // };

    return (
        <div>
            {documents?.length > 0 && (
                <div>
                    <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                        <ChevronLeftOutlinedIcon className="cursor-pointer " />
                    </div>
                    <Slider ref={sliderRef} {...settings}>
                        {documents?.length > 0 && documents.map((item, index) => (
                            <div key={index}>
                                {getImageType(item) ? (
                                    <video id={`videoId_${item?.id}`}
                                        ref={videoRef}
                                        autoPlay
                                        loop
                                        muted
                                        // onLoadedMetadata={handleLoadedMetadata}
                                        playsInline src={item.location} alt={`slide-${index}`} width={610} height={354} style={{ objectFit: 'contain' }} >
                                    </video>
                                ) : (
                                    <img src={item.location} alt={item.name} width={610} height={354} style={{ borderRadius: '5px', width: '100%' }} />
                                )}
                            </div>
                        ))}
                    </Slider>
                    <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                        <ChevronRightOutlinedIcon style={{ cursor: 'pointer' }} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default AmenitiesSlider;
