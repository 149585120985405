import React, { useState, useEffect, useRef } from "react";
import Slider from 'react-slick';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { Modal, Box } from '@mui/material';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { getImageType } from "./amenities_icon";
import { SliderModalComponent } from "./SliderModal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted width for responsiveness
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'transparent',
                color: '#333',
                left: '-15px',
                zIndex: 1,
                padding: '10px',
                top: '35%',
                transform: 'translate(-35%, -35%)',
            }}
            onClick={onClick}
        >
            <NavigateBeforeOutlinedIcon />
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'transparent',
                color: '#333',
                right: '15px',
                zIndex: 1,
                padding: '10px',
                top: '35%',
                transform: 'translate(-35%, -35%)',
            }}
            onClick={onClick}
        >
            <NavigateNextOutlinedIcon />
        </div>
    );
};

const CommonSlider = ({ images }) => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);
    const slidesToShow = images?.length < 4 ? images?.length : 4;
    const thumbnailHeight = 197;
    const numThumbnails = images.length;
    const thumbnailImageHeight = numThumbnails > 0 ? (numThumbnails > 4 ? thumbnailHeight / 4 : thumbnailHeight / numThumbnails) : thumbnailHeight;
    const [modalOpen, setModalOpen] = useState(false);
    const [sliderValue, setSliderValue] = useState(1);
    const [sliderAutoPlay, setSliderAutoPlay] = useState(3000);
    const [sliderValudeForModal, setSliderValueForModal] = useState(0);
    const [selectedPicture, setSelectedPicture] = useState();
    const videoRef = useRef(null);
    const [duration, setDuration] = useState(3000)

    // useEffect(() => {
    //     const videoIdElement = document.getElementById('videoId');
    //     if (videoIdElement) {
    //         videoIdElement.play();
    //     }
    // }, [sliderValue]);

    const handleOpenModal = (img) => {
        setSelectedPicture(img)
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedPicture()
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 767);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const goToPrevSlide = () => {
        if (sliderRef1.current) {
            sliderRef1.current.slickPrev();
        }
    };
    const goToNextSlide = () => {
        if (sliderRef1.current) {
            sliderRef1.current.slickNext();
        }
    };

    useEffect(() => {
        const videoIdElementModal = document.getElementById('videoIdModal');
        if (videoIdElementModal) {
            videoIdElementModal.play();
        }
    }, [sliderValudeForModal]);

    const handleLoadedMetadata = async (item) => {
        const video = videoRef.current;
        if (video) {
            setDuration({
                time: video.duration * 1000,
                id: item?.id
            })
            const playPromise = video.play();
            if (playPromise !== null) {
                playPromise.catch(() => { video.play(); })
            }
            

        }
    };

    return (

        <div className="slider-container property_img property_desc_slider desc_slider">
            {((!isSmallScreen && images?.length < 2) || (isSmallScreen && images?.length === 1)) ?
                (<div className="grid-container">
                    {images?.map((image, index) => (
                        <div className="grid-item" key={index}>
                            {getImageType(image) ? (
                                <video id="videoId"
                                    autoPlay
                                    loop
                                    muted
                                    ref={videoRef}
                                    // controls
                                    onLoadedMetadata={() => handleLoadedMetadata(image)}
                                    playsInline src={image.location} style={{ width: '100%', height: '220px', objectFit: 'contain', borderRadius: '6px' }} onClick={() => handleOpenModal(image)} >
                                </video>
                            ) :
                                <img src={image.location} alt={image.name} style={{ width: '330px', height: '220px', objectFit: 'cover', borderRadius: '6px' }} onClick={() => handleOpenModal(image)} />}
                        </div>
                    ))}
                </div>)
                :
                (
                    <>
                        <Slider
                            asNavFor={nav2}
                            ref={sliderRef1}
                            arrows={false}
                            autoplay={true}
                            autoplaySpeed={getImageType(sliderAutoPlay) && sliderAutoPlay?.id === duration?.id ? duration?.time : 3000}
                            beforeChange={(current, next) => {
                                setSliderAutoPlay(images[next])
                            }}
                            afterChange={(current) => {
                                // Optional: Update navigation state here if needed
                            }}
                        >
                            {images.map((image, index) => (
                                <div key={index}>
                                    {getImageType(image) ? (
                                        <video
                                            id={`videoId_${image.id}`}
                                            autoPlay
                                            loop
                                            muted
                                            // controls
                                            ref={videoRef}
                                            onLoadedMetadata={() => handleLoadedMetadata(image)}
                                            playsInline src={image.location} className="main_slider_desc" style={{ width: '100%', height: '220px', objectFit: 'contain', borderRadius: '6px' }} onClick={() => handleOpenModal(image)}>
                                        </video>
                                    ) :
                                        <img src={image.location} alt={`slide-${index}`} className="main_slider_desc" style={{ width: '100%', height: '220px', objectFit: 'cover', borderRadius: '6px' }} onClick={() => handleOpenModal(image)} />}
                                </div>
                            ))}
                        </Slider>
                        <Slider
                            className="slider_thumbnail"
                            asNavFor={nav1}
                            ref={sliderRef2}
                            slidesToShow={slidesToShow}
                            swipeToSlide={false}
                            focusOnSelect={true}
                            arrows={false}
                            infinite={true}
                            vertical={true}
                            autoplay={false}
                            autoplaySpeed={3000}
                        >
                            {images.map((image, index) => (
                                <div key={index} className={`thumbnail-slide ${index === images.length - 1 ? 'last-slide' : ''}`}>
                                    {getImageType(image) ? (
                                        <video
                                            src={image?.location}
                                            alt={`slide-${index}`}
                                            style={{ width: '100%', height: `${thumbnailImageHeight}px`, objectFit: 'cover', borderRadius: '2px', padding: index === 0 ? '0 4px 0 4px' : index === images.length - 1 ? '0 4px 0 4px' : '0 4px' }} onClick={() => handleOpenModal(image)} >
                                        </video>
                                    ) :
                                        <img
                                            src={image?.location}
                                            alt={`slide-${index}`}
                                            style={{ width: '100%', height: `${thumbnailImageHeight}px`, objectFit: 'cover', borderRadius: '2px', padding: index === 0 ? '0 4px 0 4px' : index === images.length - 1 ? '0 4px 0 4px' : '0 4px' }}
                                            onClick={() => handleOpenModal(image)}
                                        />}
                                </div>
                            ))}
                        </Slider>
                    </>
                )
            }
            {selectedPicture && modalOpen && (
                <SliderModalComponent
                    open={modalOpen}
                    handleClose={handleCloseModal}
                    selectedPicture={selectedPicture}
                    images={images}
                />
                // <Modal
                //     open={modalOpen}
                //     onClose={handleCloseModal}
                //     aria-labelledby="modal-modal-title"
                //     aria-describedby="modal-modal-description"
                // >
                //     <Box sx={style}>
                //         <div className="d-flex justify-content-between align-items-center">
                //             <div className="crossIcon cursor-pointer position-absolute">
                //                 <CloseOutlined onClick={handleCloseModal} />
                //             </div>
                //         </div>
                //         <div className="amenityImagesSlider">
                //             {images.length === 1 ? (
                //                 getImageType(selectedPicture) ? (
                //                     <video
                //                         id="videoIdModal"
                //                         autoPlay
                //                         loop
                //                         muted
                //                         playsInline
                //                         src={selectedPicture.location} alt={selectedPicture?.name} className="slider_img" >
                //                     </video>
                //                 ) :
                //                     <img src={selectedPicture.location} alt={selectedPicture.name} className="slider_img" />
                //             ) : (
                //                 <>
                //                     <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                //                         <ChevronLeftOutlinedIcon className="cursor-pointer" />
                //                     </div>
                //                     <Slider
                //                         asNavFor={sliderRef2.current}
                //                         ref={sliderRef1}
                //                         arrows={false}
                //                         // autoplay={true}
                //                         beforeChange={(current, next) => {
                //                             setSliderValueForModal(next)
                //                         }}
                //                         // autoplaySpeed={3000}
                //                     >
                //                         <div>
                //                             {
                //                                 getImageType(selectedPicture) ? (
                //                                     <video
                //                                         id="videoIdModal"
                //                                         autoPlay
                //                                         loop
                //                                         muted
                //                                         playsInline
                //                                         src={selectedPicture.location} alt={`slide-${0}`} style={{ height: '400px', width: '100%' }}>
                //                                     </video>
                //                                 ) : <img src={selectedPicture.location} alt={`slide-${0}`} style={{ height: '400px', width: '100%' }} />
                //                             }
                //                         </div>
                //                         {images.map((image, index) => (
                //                             image?.id !== selectedPicture?.id && (
                //                                 <div key={index}>
                //                                     {getImageType(image) ? (
                //                                         <video
                //                                             id="videoIdModal"
                //                                             autoPlay
                //                                             loop
                //                                             muted
                //                                             playsInline
                //                                             src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }}>
                //                                         </video>) :
                //                                         <img src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }} />}
                //                                 </div>
                //                             )
                //                         ))}
                //                     </Slider>
                //                     <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                //                         <ChevronRightOutlinedIcon className="cursor-pointer" />
                //                     </div>
                //                 </>
                //             )}
                //         </div>
                //     </Box>
                // </Modal>
            )}
        </div>
    )
}

export default CommonSlider;
