import React, { useState, useRef } from "react";
import Slider from 'react-slick';
import { getImageType } from "../Common/amenities_icon";
import { SliderModalComponent } from "../Common/SliderModal";
import CustomSlider from "../Common/CustomSlider";

export const PropertySlider = ({ pictures, ids, key }) => {
    // Slider setting
    const [nav1] = useState(null);
    const [nav2] = useState(null);
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);
    const [selectedPicture, setSelectedPicture] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    // const [sliderAutoPlay, setSliderAutoPlay] = useState(3000);
    // const [duration, setDuration] = useState(null);
    // const sliderIdRef = useRef(null)
    // const sliderAutoPlayRef = useRef(null)
    // console.log('ids', ids, key)

    // useEffect(() => {
    //     if (ids?.length > 0) {
    //         const findPicture = pictures?.find((item) => ids.includes(item?.property_picture_id))
    //         sliderIdRef.current = findPicture?.property_picture_id
    //         console.log(sliderIdRef.current, 'hello')
    //     }
    // }, [ids])

    const handleOpenModal = (img) => {
        setSelectedPicture(img)
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedPicture()
    };

    const slidesToShow = pictures.length < 4 ? pictures.length : 4;
    // const videoRef = useRef(null);
    // const handleLoadedMetadata = async (item) => {
    //     const video = videoRef.current;
    //     console.log('video', video, ids)
    //     // const sliderId = document.getElementById(`slider_${item?.property_picture_id}`);
    //     if (video) {
    //         console.log('test value', item?.property_picture_id, sliderIdRef.current)
    //         if (item?.property_picture_id === sliderIdRef?.current && ids.includes(item?.property_picture_id)) {
    //             setDuration({
    //                 time: video.duration * 1000,
    //                 id: item?.id,
    //                 property_id: item?.property_picture_id
    //             });
    //             console.log(video.duration * 1000, 'test data123', sliderIdRef.current, sliderAutoPlay)
    //             const playPromise = video.play();
    //             sliderAutoPlayRef.current = video.duration * 1000
    //             // slider.autoplaySpeed = video.duration*1000
    //             if (playPromise !== null) {
    //                 playPromise.catch(() => { video.play(); })
    //             }
    //         }
    //     }
    // };

    return (
        <div className="pro_slider">
            {
                pictures.length === 1 ? (
                    getImageType(pictures[0]) ? (
                        <video
                            id={`videoId_${pictures[0]?.id}`}
                            autoPlay
                            // ref={videoRef}
                            disablePictureInPicture
                            loop
                            muted
                            playsInline
                            // controls
                            src={pictures[0].location} width="610" height="354" style={{ borderRadius: '5px', width: '100%', objectFit: 'contain' }} onClick={() => handleOpenModal(pictures[0])}>
                        </video>
                    ) :
                        <img loading="lazy" src={pictures[0].location} alt={pictures[0].name} className="slider_img" onClick={() => handleOpenModal(pictures[0])} />
                ) : (
                    <>
                        <CustomSlider
                            files={pictures}
                            imagesDelay={4000}
                            asNavFor={nav2}
                            ref={sliderRef1}
                            handleOpenModal={handleOpenModal}
                        />
                        {/* <Slider
                        asNavFor={nav2}
                        ref={sliderRef1}
                        id={`slider_${sliderIdRef.current}`}
                        arrows={false}
                        autoplay={true}
                        speed={500}
                        autoplaySpeed={getImageType(sliderAutoPlay) ? ((duration?.id === sliderAutoPlay?.id && duration?.property_id === sliderIdRef.current) ? duration?.time : 3000) : 3000}
                        beforeChange={async (current, next) => {
                            setSliderAutoPlay(pictures[next])
                        }}
                    >
                        {pictures.map((image, index) => (
                            <div key={index}>
                                {getImageType(image) ? (
                                    <video id={`videoId_${image?.id}`}
                                        autoPlay
                                        loop
                                        muted
                                        ref={videoRef}
                                        // controls
                                        onLoadedMetadata={() => handleLoadedMetadata(image)}
                                        onEnded={() => console.log('on ended')}
                                        playsInline
                                        src={image.location} alt={`slide-${index}`} className="main_slide_img" style={{ objectFit: 'contain' }} onClick={() => handleOpenModal(image)} >
                                    </video>
                                ) :
                                    <img src={image.location} alt={`slide-${index}`} className="main_slide_img" onClick={() => handleOpenModal(image)} />}
                            </div>
                        ))}
                    </Slider> */}
                        <Slider
                            className="slider_thumbnail"
                            asNavFor={nav1}
                            ref={sliderRef2}
                            slidesToShow={slidesToShow}
                            swipeToSlide={false}
                            focusOnSelect={true}
                            arrows={false}
                            infinite={false}
                        >
                            {pictures.map((image, index) => (
                                <div key={index} className={`thumbnail-slide ${index === pictures.length - 1 ? 'last-slide' : ''}`}>
                                    {getImageType(image) ? (
                                        <video
                                            src={image?.location}
                                            alt={`slide-${index}`}
                                            style={{ width: '100%', height: '80px', objectFit: 'cover', borderRadius: '2px', padding: index === 0 ? '0 4px 0 0' : index === pictures.length - 1 ? '0 0 0 4px' : '0 4px' }} onClick={() => handleOpenModal(image)} >
                                        </video>) :
                                        <img
                                            src={image?.location}
                                            alt={`slide-${index}`}
                                            style={{ width: '100%', height: '80px', objectFit: 'cover', borderRadius: '2px', padding: index === 0 ? '0 4px 0 0' : index === pictures.length - 1 ? '0 0 0 4px' : '0 4px' }}
                                            onClick={() => handleOpenModal(image)}
                                        />}
                                </div>
                            ))}
                        </Slider>
                    </>
                )
            }

            {selectedPicture && modalOpen && (
                <SliderModalComponent
                    open={modalOpen}
                    handleClose={handleCloseModal}
                    selectedPicture={selectedPicture}
                    images={pictures}
                />
                // <Modal
                //     open={modalOpen}
                //     onClose={handleCloseModal}
                //     aria-labelledby="modal-modal-title"
                //     aria-describedby="modal-modal-description"
                // >
                //     <Box sx={style}>
                //         <div className="d-flex justify-content-between align-items-center">
                //             <div className="crossIcon cursor-pointer position-absolute">
                //                 <CloseOutlined onClick={handleCloseModal} />
                //             </div>
                //         </div>
                //         <div className="amenityImagesSlider">
                //             {pictures?.length === 1 ? (
                //                 getImageType(selectedPicture) ? (
                //                     <video
                //                         id="videoIdModal"
                //                         autoPlay
                //                         loop
                //                         muted
                //                         playsInline
                //                         src={selectedPicture.location} alt={selectedPicture?.name} className="slider_img" >
                //                     </video>
                //                 ) :
                //                     <img src={selectedPicture?.location} alt={selectedPicture?.name} className="slider_img" />
                //             ) : (
                //                 <>
                //                     <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                //                         <ChevronLeftOutlinedIcon className="cursor-pointer" />
                //                     </div>
                //                     <Slider
                //                         asNavFor={sliderRef2.current}
                //                         ref={sliderRef1}
                //                         arrows={false}
                //                         autoplay={true}
                //                         autoplaySpeed={modalDuration}
                //                         beforeChange={(current, next) => {
                //                             setSliderValueForModal(next)
                //                         }}
                //                     >
                //                         <div>
                //                             {
                //                                 getImageType(selectedPicture) ? (
                //                                     <video
                //                                         id="videoIdModal"
                //                                         autoPlay
                //                                         loop
                //                                         muted
                //                                             playsInline
                //                                             ref={videoModalRef}
                //                                         src={selectedPicture.location} alt={`slide-${0}`} style={{ height: '400px', width: '100%' }}>
                //                                     </video>
                //                                 ) : <img src={selectedPicture.location} alt={`slide-${0}`} style={{ height: '400px', width: '100%' }} />
                //                             }
                //                         </div>
                //                         {pictures.map((image, index) => (
                //                             image?.id !== selectedPicture?.id && (
                //                                 <div key={index}>
                //                                     {getImageType(image) ? (
                //                                         <video
                //                                             id="videoIdModal"
                //                                             autoPlay
                //                                             loop
                //                                             muted
                //                                             playsInline
                //                                             src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }}>
                //                                         </video>) :
                //                                         <img src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }} />}
                //                                 </div>
                //                             )
                //                         ))}
                //                     </Slider>
                //                     <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                //                         <ChevronRightOutlinedIcon className="cursor-pointer" />
                //                     </div>
                //                 </>
                //             )}
                //         </div>
                //     </Box>
                // </Modal>
            )}

        </div>
    );
};
