import React, { useState, useEffect, useRef } from "react";
import Container from '@mui/material/Container';
import PropertyDescriptionBook from "../../components/Property/propertyDescriptionBook";
import Button from '@mui/material/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Slider from 'react-slick';
import Room1 from "../../assets/images/no-room-image_1735808445420.png";
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { useNavigate, useLocation } from 'react-router-dom';
import { HouseRuleModal } from "../../components/Property/houseRuleModal";
import { CAUTION_MONEY_TYPE, GLOBAL_SETTINGS_KEY, PROPERTY_STATUS, SOCKET_EVENT } from "../../common/constant";
import { InterestedTenantModal } from "../../components/Property/interestedTenantModal";
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import { Modal, Box } from '@mui/material';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import dayjs from "dayjs";
import TextField from '@mui/material/TextField';
import { applyDiscount } from "../../api/global-settings";
import { socket } from "../../utils/socket";
import { getS3BaseUrl } from "../../helpers/string_helper";
import { toast } from "react-toastify";
import { getImageType } from "../../components/Common/amenities_icon";
import { SliderModalComponent } from "../../components/Common/SliderModal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted width for responsiveness
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export const BookRoom = () => {
    const location = useLocation();
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const propertyDetails = location?.state?.roomData ? JSON.parse(location?.state?.roomData) : null
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [isInterestedModal, setIsInterestedModal] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [roomData, setRoomData] = useState();
    const [totalPayableAmount, setTotalPayableAmount] = useState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [socketData, setSocketData] = useState()
    const [foodDocument, setFoodDocument] = useState();
    const s3BaseUrl = getS3BaseUrl()
    const [customDepositSocket, setCustomDepositSocket] = useState();
    const [globalData, setGlobalData] = useState({
        deposit_amount: '',
        platform_fee: '',
        pre_booking: ''
    })
    const [addTenantSocket, setAddTenantSocket] = useState()
    const videoRef = useRef(null)
    // const [duration, setDuration] = useState()
    const [selectedPicture, setSelectedPicture] = useState();

    const handleLoadedMetadata = async () => {
        const video = videoRef.current;
        if (video) {
            const playPromise = video.play();
            if (playPromise !== null) {
                playPromise.catch(() => { video.play(); })
            }
        }
    };

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    useEffect(() => {
        if (location?.state?.roomData) {
            let total = 0
            propertyDetails?.basicDetails?.global_data?.forEach((item) => {
                if (item.name === GLOBAL_SETTINGS_KEY.PLATFORM_FEE) {
                    total = total + item.payload?.value
                    setGlobalData((pre) => {
                        return {
                            ...pre,
                            platform_fee: item
                        }
                    })
                }
                if (item.name === GLOBAL_SETTINGS_KEY.PRE_BOOKING_DAYS) {
                    setGlobalData((pre) => {
                        return {
                            ...pre,
                            pre_booking: item
                        }
                    })
                }
            })

            let deposit_amount = 0
            if (propertyDetails?.basicDetails?.caution_money_type === CAUTION_MONEY_TYPE.FIXED_RATE) {
                deposit_amount = deposit_amount + parseInt(propertyDetails?.basicDetails?.fixed_rate)
            } else if (propertyDetails?.basicDetails?.caution_money_type === CAUTION_MONEY_TYPE.EQUAL_TO_BED_RATE) {
                deposit_amount = deposit_amount + parseInt(propertyDetails?.bed?.bed?.price)
            } else {
                deposit_amount = deposit_amount + (propertyDetails?.bed?.bed?.caution_money > 0 ? parseInt(propertyDetails?.bed?.bed?.caution_money) : parseInt(propertyDetails?.bed?.bed?.price))
            }
            total = total + deposit_amount
            setGlobalData((pre) => {
                return {
                    ...pre,
                    deposit_amount: deposit_amount
                }
            })
            setTotalPayableAmount(total)
            setRoomData(propertyDetails)
        } else {
            navigate("/")
        }
    }, [location?.state?.roomData])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENT.PROPERTY_STATUS_EMIT, (data) => {
                if ((!socketData || socketData?.status !== data?.data?.status?.label) && propertyDetails?.basicDetails?.id === data?.data?.property_id) {
                    setSocketData({
                        status: data?.data?.status?.label,
                        id: data?.data?.property_id
                    })
                }
            });
            socket.on(SOCKET_EVENT.ADD_TENANT_EMIT, (data) => {
                if ((!addTenantSocket || addTenantSocket?.bedData?.id !== data?.bedData?.id) && data?.property_id === propertyDetails?.basicDetails?.id && data?.bedData?.id === propertyDetails?.bed?.bed?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setAddTenantSocket(data)
                    navigate('/property-detail', {
                        state: {
                            propertyId: JSON.stringify(propertyDetails?.basicDetails)
                        }
                    })
                }
            });
            socket.on(SOCKET_EVENT.BED_BOOKING_EMIT, (data) => {
                if ((!addTenantSocket || addTenantSocket?.bed_id !== data?.bed_id) && data?.property_id === propertyDetails?.basicDetails?.id && data?.bed_id === propertyDetails?.bed?.bed?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setAddTenantSocket(data)
                    navigate('/property-detail', {
                        state: {
                            propertyId: JSON.stringify(propertyDetails?.basicDetails)
                        }
                    })
                }
            })
            socket.on(SOCKET_EVENT.FOOD_MENU_EMIT, (data) => {
                if ((!foodDocument || foodDocument?.property_id !== data?.data?.property_id) && data?.data?.status === PROPERTY_STATUS.LIVE && propertyDetails?.basicDetails?.id === data?.data?.property_id) {
                    setFoodDocument({
                        documents: data?.data?.documents,
                        property_id: data?.data?.property_id,
                        status: data?.data?.status
                    })
                }
            });
            socket.on(SOCKET_EVENT.CUSTOM_DEPOSIT_EMIT, (data) => {
                if ((!customDepositSocket || customDepositSocket?.property_id !== data?.property_id) && data?.property_id === propertyDetails?.basicDetails?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setCustomDepositSocket(data)
                }
            })
        }
    }, [socket]);

    useEffect(() => {
        if (roomData && roomData?.basicDetails?.id === foodDocument?.property_id) {
            const food_menu_document = []
            let isNewFoodMenu = false
            if (foodDocument?.documents?.length > 0) {
                foodDocument?.documents?.forEach((menu) => {
                    if (menu?.size) {
                        isNewFoodMenu = true
                        const filterDocument = roomData?.basicDetails?.food_menu_documents?.find((item) => item?.id === menu?.id)
                        if (!filterDocument) {
                            food_menu_document.push({ ...menu, location: `${s3BaseUrl}${menu?.location}` })
                        }
                    }
                })
            }

            const documents = isNewFoodMenu && roomData?.basicDetails?.food_menu_documents?.length > 0 ? [...roomData?.basicDetails?.food_menu_documents, ...food_menu_document] : (isNewFoodMenu ? food_menu_document : foodDocument?.documents)
            setRoomData({
                ...roomData,
                basicDetails: {
                    ...roomData?.basicDetails,
                    food_menu_documents: documents
                }
            })
        }

    }, [foodDocument])

    useEffect(() => {
        if (socketData?.id === propertyDetails?.basicDetails?.id && socketData?.status === PROPERTY_STATUS.ACTIVE) {
            navigate('/')
        }
    }, [socketData])

    useEffect(() => {
        if (customDepositSocket && roomData && roomData?.basicDetails?.id === customDepositSocket?.property_id) {
            let deposit_amount = 0
            if (!customDepositSocket?.bed_id) {
                if (customDepositSocket?.caution_money_type === CAUTION_MONEY_TYPE.FIXED_RATE) {
                    deposit_amount = deposit_amount + parseInt(customDepositSocket?.fixed_rate)
                } else if (customDepositSocket?.caution_money_type === CAUTION_MONEY_TYPE.EQUAL_TO_BED_RATE) {
                    deposit_amount = deposit_amount + parseInt(roomData?.bed?.bed?.price)
                } else if (customDepositSocket?.caution_money_type === CAUTION_MONEY_TYPE.CUSTOM && !customDepositSocket?.caution_money) {
                    deposit_amount = deposit_amount + (roomData?.bed?.bed?.caution_money > 0 ? parseInt(roomData?.bed?.bed?.caution_money) : parseInt(roomData?.bed?.bed?.price))
                }
            }
            if (customDepositSocket?.caution_money_type === CAUTION_MONEY_TYPE.CUSTOM && customDepositSocket?.bed_id === roomData?.bed?.bed?.id) {
                deposit_amount = deposit_amount + parseInt(customDepositSocket?.caution_money)
                setRoomData({
                    ...roomData,
                    bed: {
                        ...roomData?.bed,
                        bed: {
                            ...roomData?.bed?.bed,
                            caution_money: customDepositSocket.caution_money
                        }
                    }
                })
            }

            if (deposit_amount > 0) {
                setTotalPayableAmount(globalData?.platform_fee?.payload?.value + deposit_amount - discountAmount)
                setGlobalData((pre) => {
                    return {
                        ...pre,
                        deposit_amount: deposit_amount
                    }
                })
            }
        }
    }, [customDepositSocket])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOpenModal = (img) => {
        setSelectedPicture(img)
        setModalOpen(true);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const settingsNav2 = {
        slidesToShow: roomData?.pictures?.length < 4 ? roomData?.pictures?.length : 4,
        swipeToSlide: false,
        focusOnSelect: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const navigate = useNavigate();

    const proceedToBook = (propertyDetails) => {
        // setIsInterestedModal(true, { state: { propertyDetails } })
        navigate('/book-form', { state: { propertyDetails } });
    }
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedPicture()
    };
   
    const [code, setCode] = useState('');
    const [applied, setApplied] = useState(false);

    const handleApplyClick = async () => {
        try {
            if (code) {
                const response = (await applyDiscount({ code: code, owner_id: roomData?.owner_id }))?.data?.data
                if (response?.success && response?.discountAmount > 0) {
                    if (roomData?.bed?.bed?.price <= response?.discountAmount) {
                        return toast.error('Discount is not applied as caution money amount is less or equal to the discount value')
                    } else {
                        setTotalPayableAmount(totalPayableAmount - response?.discountAmount)
                        setDiscountAmount(response?.discountAmount)
                    }
                } else if (response?.success && response?.custom_payout_percentage > 0) {
                    setRoomData({ ...roomData, custom_payout_percentage: response?.custom_payout_percentage })
                }
                setApplied(true);
            }
        } catch (err) {
            console.log(err)
        }

    };

    const handleRemoveClick = () => {
        setCode(''); // Clear the input field
        setApplied(false); // Reset the applied state
        setTotalPayableAmount(totalPayableAmount + discountAmount)
        setDiscountAmount(0)
        setRoomData({ ...roomData, custom_payout_percentage: 0 })
    };

    return (
        <div className='landing_pg detail_pg'>
            <section className="available_rooms_listing">
                <Container>
                    <div className='available_room_detail'>
                        <PropertyDescriptionBook propertyDetails={roomData?.basicDetails} />
                        <div className='bed_matrix_block book_bed_matrix_block'>
                            <div className='d-flex align-items-center justify-content-between bed_mat_whole'>
                                <div className="page_header main_bed_header">
                                    <span className="bed_heading">Bed Details :</span>
                                    {!addTenantSocket && roomData?.leaving_date && (
                                        <span className='bed_availability theme_color my-2' style={{ fontSize: '18px' }}> &nbsp;Bed will be available from {dayjs(roomData?.leaving_date).add(1, 'days').format('MMM D, YYYY')}</span>
                                    )}
                                    {(addTenantSocket) && (
                                        <span className='bed_availability theme_color my-2' style={{ fontSize: '18px' }}> &nbsp;Currently Bed is not available </span>
                                    )}
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <div class="border me-2" >
                                        <div class="content">
                                            <span style={{ color: '#ED3542' }}>Monthly Rent: ₹ {roomData?.bed?.bed?.price}/-</span>
                                        </div>
                                    </div>
                                    {roomData?.basicDetails?.rules?.length > 0 && (
                                        <Button className='theme_button sign_in_button' style={{ zIndex: 0 }} onClick={handleOpen}><RemoveRedEyeOutlinedIcon className='me-2' />House Rule</Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="property_listing">
                        <div className="property_card">
                            <div className="single_property_card">
                                <div className="slider-container property_img">
                                    {roomData?.pictures?.length === 0 && (
                                        <img src={Room1} alt={Room1} style={{ width: '100%', height: '480px', objectFit: 'cover', borderRadius: '8px' }} />
                                    )}
                                    {roomData?.pictures?.length === 1 ? (
                                        getImageType(roomData?.pictures[0]) ? (
                                            <video id="videoId"
                                                autoPlay
                                                loop
                                                muted
                                                ref={videoRef}
                                                // controls
                                                onLoadedMetadata={handleLoadedMetadata}
                                                playsInline src={roomData?.pictures[0].location} style={{ width: '100%', height: '480px', objectFit: 'contain', borderRadius: '8px' }} onClick={() => handleOpenModal(roomData?.pictures[0])} >
                                            </video>
                                        ) :
                                            <img src={roomData?.pictures[0].location} alt={roomData?.pictures[0].name} style={{ width: '100%', height: '480px', objectFit: 'cover', borderRadius: '8px' }} />
                                    ) : (
                                        <>
                                            <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)} arrows={false} autoplay={false}>
                                                {roomData?.pictures?.map((image, index) => (
                                                    <div key={index}>
                                                        {getImageType(image) ? (
                                                            <video id="videoId"
                                                                autoPlay
                                                                loop
                                                                muted
                                                                // controls
                                                                ref={videoRef}
                                                                onLoadedMetadata={handleLoadedMetadata}
                                                                playsInline src={image.location} style={{ width: '100%', height: '350px', objectFit: 'contain', borderRadius: '8px' }} onClick={() => handleOpenModal(image)} >
                                                            </video>
                                                        ) :
                                                            <img src={image.location} alt={image.name} style={{ width: '100%', height: '350px', borderRadius: '8px', objectFit: 'cover' }} onClick={() => handleOpenModal(image)} />}
                                                    </div>
                                                ))}
                                            </Slider>
                                            <Slider asNavFor={nav1} ref={slider => (sliderRef2 = slider)} {...settingsNav2}>
                                                {roomData?.pictures?.map((image, index) => (
                                                    <div key={index}>
                                                        {getImageType(image) ? (
                                                            <video src={image.location} style={{ width: '100%', height: '80px', objectFit: 'contain' }} >
                                                            </video>
                                                        ) :
                                                            <img src={image.location} alt={image.name} style={{ width: '100%', height: '80px', objectFit: 'cover' }} />}
                                                    </div>
                                                ))}
                                            </Slider>
                                        </>
                                    )}
                                </div>
                                <div className="d-flex flex-column">
                                    <ul className="bedroom_details list-inline d-flex flex-wrap mb-2">
                                        <li><CorporateFareOutlinedIcon /> &nbsp;Floor No. <span>{roomData?.bed?.floorNo}</span></li>
                                        {roomData?.bed?.flatNo && <li><BusinessOutlinedIcon /> &nbsp;Flat No. <span>{roomData?.bed?.flatNo}</span></li>}
                                        <li><OtherHousesOutlinedIcon /> &nbsp;Room No. <span>{roomData?.bed?.roomNo}</span></li>
                                        <li><HotelOutlinedIcon /> &nbsp;Bed No. <span>{roomData?.bed?.bed?.name}</span></li>
                                    </ul>
                                    <div className="price_detail_card w-100">
                                        <div className="price_detail_header payment_area">Price Details</div>
                                        <ul className="price_detail_body payment_area">
                                            <li className="d-flex justify-content-between">Type <span className="price_amt">{roomData?.room_type}</span></li>
                                            <li className="d-flex justify-content-between">Caution Money<span className="price_amt">₹ {globalData?.deposit_amount}/-</span></li>
                                            <li className="d-flex justify-content-between rent_amt">
                                                {/* <span>Rent Amount <InfoOutlinedIcon onClick={handleClick} style={{ fontSize: '15px', cursor: 'pointer' }} /></span> */}
                                                {/* <span className="price_amt">₹ {roomData?.bed?.bed?.price}/-</span> */}
                                            </li>
                                            <li className="d-flex justify-content-between">{globalData?.platform_fee?.display_name} <span className="price_amt">₹ {globalData?.platform_fee?.payload?.value}/-</span></li>
                                            <li>
                                                <div>
                                                    {applied ? (
                                                        <>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-2 align-items-center">
                                                                    <button className="remove_code_button" style={{ flexGrow: 1 }}><span>{code}</span></button>
                                                                    <a className="cursor-pointer" onClick={handleRemoveClick}>
                                                                        Remove Code
                                                                    </a>
                                                                </div>
                                                                <div><p className="mb-0 price_amt">- ₹{discountAmount}/-</p></div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <TextField
                                                                placeholder="Apply Discount Code / Referral Code"
                                                                variant="outlined"
                                                                size="small"
                                                                value={code}
                                                                onChange={(e) => setCode(e.target.value)}
                                                                sx={{ flexGrow: 1 }}
                                                            />
                                                            <a aria-disabled={!code} className="cursor-pointer" onClick={handleApplyClick}>
                                                                Click here to Apply
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </li>
                                            <li className="d-flex justify-content-between total_amt">
                                                <div className="total_payable_amount">
                                                    <h5>Total Payable amount for booking</h5>
                                                    <span>Including the deposit and agreement charges </span>
                                                </div>
                                                <span className="price_amt">₹ {totalPayableAmount}/-</span>
                                            </li>
                                        </ul>
                                        <div className="payment_btn payment_area mb-2">
                                            <Button className='theme_button sign_in_button'
                                                disabled={addTenantSocket}
                                                onClick={() => proceedToBook({ basicDetails: roomData, globalData, totalPayableAmount, ...(applied && code && { promoCode: code, discountAmount }) })}
                                            >Proceed to Book</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            {open && (
                <HouseRuleModal open={open} handleClose={handleClose} rules={roomData?.basicDetails?.rules} />
            )}

            {isInterestedModal && (
                <InterestedTenantModal isOpen={isInterestedModal} handleClose={() => setIsInterestedModal(false)} bedDetails={{
                    bedType: roomData?.room_type,
                    propertyName: roomData?.basicDetails?.name
                }} />
            )}

            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="rent_amt"
            >
                <Typography sx={{ p: 2 }}>Rent will be applicable
                    from the 1st entry date</Typography>
            </Popover>
            {/* <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="crossIcon cursor-pointer position-absolute">
                            <CloseOutlined onClick={handleCloseModal} />
                        </div>
                    </div>
                    <div className="amenityImagesSlider">
                        {roomData?.pictures?.length === 1 ? (
                            <img src={roomData?.pictures[0].location} alt={roomData?.pictures[0].name} className="slider_img" />
                        ) : (
                            <>
                                <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                                    <ChevronLeftOutlinedIcon className="cursor-pointer" />
                                </div>
                                <Slider
                                    asNavFor={sliderRef2.current}
                                    ref={sliderRef1}
                                    arrows={false}
                                    autoplay={true}
                                    // autoplaySpeed={3000}
                                >
                                    {roomData?.pictures?.map((image, index) => (
                                        <div key={index}>
                                            {getImageType(image) ? (
                                                <video
                                                    id={`videoId_${image?.id}`}
                                                    ref={videoRef}
                                                    autoPlay
                                                    loop
                                                    muted
                                                    // onLoadedMetadata={handleLoadedMetadata}
                                                    playsInline src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%', objectFit: 'contain' }} >
                                                </video>
                                            ) : (
                                                <img src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }} />
                                            )}

                                        </div>
                                    ))}
                                </Slider>
                                <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                                    <ChevronRightOutlinedIcon className="cursor-pointer" />
                                </div>
                            </>
                        )}
                    </div>
                </Box>
            </Modal> */}
            {selectedPicture && modalOpen && (
                <SliderModalComponent
                    open={modalOpen}
                    handleClose={handleCloseModal}
                    selectedPicture={selectedPicture}
                    images={roomData?.pictures}
                />
            )}
        </div>

    )
}