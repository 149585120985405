import React, { useState, useEffect, useRef } from "react";
import Slider from 'react-slick';
import { Modal, Box } from '@mui/material';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { getImageType } from "./amenities_icon";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted width for responsiveness
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export const SliderModalComponent = ({ open, handleClose, images, selectedPicture }) => {
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);
    const [sliderValudeForModal, setSliderValueForModal] = useState(0);

    useEffect(() => {
        const videoIdElementModal = document.getElementById('videoIdModal');
        if (videoIdElementModal) {
            videoIdElementModal.play();
        }
    }, [sliderValudeForModal]);

    const goToPrevSlide = () => {
        if (sliderRef1.current) {
            sliderRef1.current.slickPrev();
        }
    };
    const goToNextSlide = () => {
        if (sliderRef1.current) {
            sliderRef1.current.slickNext();
        }
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="crossIcon cursor-pointer position-absolute">
                        <CloseOutlined onClick={handleClose} />
                    </div>
                </div>
                <div className="amenityImagesSlider">
                    {images.length === 1 ? (
                        getImageType(selectedPicture) ? (
                            <video
                                id="videoIdModal"
                                autoPlay
                                loop
                                muted
                                // controls
                                playsInline
                                src={selectedPicture.location} alt={selectedPicture?.name} className="slider_img" >
                            </video>
                        ) :
                            <img src={selectedPicture.location} alt={selectedPicture.name} className="slider_img" />
                    ) : (
                        <>
                            <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                                <ChevronLeftOutlinedIcon className="cursor-pointer" />
                            </div>
                            <Slider
                                asNavFor={sliderRef2.current}
                                ref={sliderRef1}
                                arrows={false}
                                // autoplay={true}
                                beforeChange={(current, next) => {
                                    setSliderValueForModal(next)
                                }}
                            // autoplaySpeed={3000}
                            >
                                <div>
                                    {
                                        getImageType(selectedPicture) ? (
                                            <video
                                                id="videoIdModal"
                                                autoPlay
                                                loop
                                                muted
                                                playsInline
                                                src={selectedPicture.location} alt={`slide-${0}`} style={{ height: '400px', width: '100%' }}>
                                            </video>
                                        ) : <img src={selectedPicture.location} alt={`slide-${0}`} style={{ height: '400px', width: '100%' }} />
                                    }
                                </div>
                                {images.map((image, index) => (
                                    image?.id !== selectedPicture?.id && (
                                        <div key={index}>
                                            {getImageType(image) ? (
                                                <video
                                                    id="videoIdModal"
                                                    autoPlay
                                                    loop
                                                    muted
                                                    playsInline
                                                    src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }}>
                                                </video>) :
                                                <img src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }} />}
                                        </div>
                                    )
                                ))}
                            </Slider>
                            <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                                <ChevronRightOutlinedIcon className="cursor-pointer" />
                            </div>
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    )
}