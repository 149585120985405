import React, { useEffect, useState } from 'react'
import { getCMSDetails } from '../../api/global-settings';
import { CMS_PAGES } from '../../constants/variables';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X'; function SocialMedia() {
  const [data, setData] = useState({})
  const fetchPageDetails = async () => {
    try {
      const resp = await getCMSDetails(CMS_PAGES.SOCIAL_MEDIA);
      setData(resp.data.data)
    } catch (e) {
      console.log(e);
    }
  }
  const getIcon = (key) => {
    switch (key) {
      case 'facebook':
        return <FacebookIcon />;
      case 'instagram':
        return <InstagramIcon />
      case 'linkedin':
        return <LinkedInIcon />;
      case 'x':
        return <XIcon />;
      case 'youtube':
        return <YouTubeIcon />
      default:
        break;
    }

  }
  useEffect(() => {
    fetchPageDetails();
  }, [])

  return (
    <section>
      <div className="cms_page about">
        <h4 className='social_media_text mt-1' dangerouslySetInnerHTML={{ __html: data.header }}></h4>
        <ul className="list-inline d-flex align-items-center social_media_icon">
          {(data?.links && Object.keys(data?.links).length > 0)
            ? Object.keys(data?.links).map((key, i) => (
              <li key={i}>
                {data.links[key] &&
                  (<Link className='mx-1' to={data.links[key]}>
                    {getIcon(key)}
                  </Link>)}
              </li>
            ))
            : ''}
        </ul>
      </div>
    </section>
  )
}

export default SocialMedia