import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({ files, imageDelay = 3000, handleOpenModal = () => { }, asNavFor, ref = null }) => {
  const sliderRef = useRef(ref);
  const [autoplaySpeed, setAutoplaySpeed] = useState(imageDelay); // Default delay for images
  const videoRefs = useRef([]); // Track video elements
  const isVideo = (file) => ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'webm'].includes(file?.name?.split('.').pop().toString());

  // Handle autoplay speed based on slide type
  const handleBeforeChange = (current, next) => {
    const nextSlide = files[next];
    if (isVideo(nextSlide)) {
      const videoElement = document.getElementById(`video-${nextSlide.id}`);
      if (videoElement) {
        videoElement.currentTime = 0;
        setAutoplaySpeed(videoElement.duration * 1000);
      }
    } else {
      setAutoplaySpeed(imageDelay); // 5 seconds for images
    }
  };

  // Play video manually when slide changes
  // const handleAfterChange = async (current) => {
  //   const currentSlide = files[current];
  //   if (isVideo(currentSlide)) {
  //     const videoElement = document.getElementById(`video-${currentSlide.id}`);
  //     if (videoElement) {
  //       // await videoElement.pause(); // Pause to reset the video
  //       // videoElement.currentTime = 0; // Reset to the beginning
  //       // await videoElement.play(); // Play video
  //     }
  //   }
  // };

  // IntersectionObserver for slider visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (sliderRef.current) {
            if (entry.isIntersecting) {
              sliderRef.current.slickPlay(); // Resume slider autoplay when in view
            } else {
              sliderRef.current.slickPause(); // Pause slider autoplay when out of view
            }
          }
        });
      },
      { threshold: 0.50 } // Play/pause when 50% of the slider is visible
    );
    const sliderElement = sliderRef.current.innerSlider.list; // Get the actual slider DOM element
    if (sliderElement) {
      observer.observe(sliderElement);
    }
    return () => {
      if (sliderElement) observer.unobserve(sliderElement);
    };
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: autoplaySpeed,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    beforeChange: handleBeforeChange,
    // afterChange: handleAfterChange, // Manually trigger video play when slide changes
    ref: sliderRef,
    asNavFor: asNavFor,
  };

  return (
    <Slider {...settings}>
      {files.map((slide, index) => (
        <div key={index}>
          {isVideo(slide) ? (
            <video
              id={`video-${slide.id}`}
              // controls
              disablePictureInPicture
              width="100%"
              ref={(el) => (videoRefs.current[index] = el)} // Store ref for each video
              alt={`slide-${index}`}
              autoPlay
              loop
              muted
              playsInline
              className="main_slide_img"
              style={{ objectFit: 'contain' }}
              onClick={() => handleOpenModal(slide)}
            >
              <source src={slide.location} type="video/mp4" />
              {/* Your browser does not support the video tag. */}
            </video>
          ) : (
            <img
              src={slide.location}
              className="main_slide_img"
              alt={`slide-${index}`}
              width="100%"
              onClick={() => handleOpenModal(slide)}
              loading="lazy"
            />
          )}
        </div>
      ))}
    </Slider>
  );
};

export default CustomSlider;
